/**
 * 封装 axios 工具类
 * 统一封装请求头、参数、错误提示、权限共功能
 */

// 导入模块
import axios from 'axios'
import Storage from '@/utils/storage.js'
import * as Cookie from '@/utils/cookie.js'
// eslint-disable-next-line no-unused-vars
import { Message } from 'element-ui'
import qs from 'qs'

// 定义常量

export const OK_CODE = 200
export const UNAUTHORIZED_CODE = 401
export const FORBIDDEN_CODE = 403
export const NOT_FOUND_CODE = 404
export const INTERNAL_SERVER_ERROR_CODE = 500
export const TOKEN_KEY = 'Token'
export const EXPIRED_TIME = 'expiredTime'
export const TOKEN_OBJECT_KEY = 'TokenObject'
export const REFRESH_TOKEN_KEY = 'RefreshToken'

// 是否正在刷新的标记
// const isRefreshing = false
// 重试队列
// const requests = []

// 定义全局变量，本机只能本地测试，生产环境不可用
// export const domain = "http://127.0.0.1:8888";
export const domain = ''

// 假设 getToken() 是从本地存储获取令牌的方法，getToken().then(token => {...})
// 假设 refreshToken() 是刷新令牌的方法，refreshToken().then(newToken => {...})
// 假设 logout() 是登出的方法，logout().then(() => {...})

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    // TODO 处理权限相关业务
    return config
  },
  (error) => {
    // TODO
    console.log(error)
    return Promise.reject(error)
  }
)

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    const res = response.data
    if (response.headers.token) {
      Storage.setItemForLs(TOKEN_KEY, response.headers.token)
    }
    // console.log(this.$Storage.getItemForLs(TOKEN_KEY))
    if (res.status === UNAUTHORIZED_CODE) {
      // console.log(res.status)
      // 状态码为 401，说明 jwt 过期
      // 清除 Storage、Cookie 中的 JWT 令牌，并跳转到登录页面
      // Storage.removeItemForLs(TOKEN_KEY)
      // Storage.removeItemForLs(TOKEN_OBJECT_KEY)
      Message({
        message: '登录已失效,请重新登录',
        type: 'error',
        duration: 5 * 1000
      })
      window.location.href = '/login'
    } else if (response.status === FORBIDDEN_CODE) {
      Message({
        message: '未认证,请重新登录',
        type: 'error',
        duration: 5 * 1000
      })
      window.location.href = '/login'
    } else if (response.status === NOT_FOUND_CODE || response.status === INTERNAL_SERVER_ERROR_CODE) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  (error) => {
    console.log('err' + error)
    if (error.response && (error.response.status === FORBIDDEN_CODE || error.response.status === UNAUTHORIZED_CODE)) {
      // 状态码为 401、403，说明 jwt 过期
      // 清除 Storage、Cookie 中的 JWT 令牌，并跳转到登录页面
      Storage.removeItemForLs(TOKEN_KEY)
      Storage.removeItemForLs(TOKEN_OBJECT_KEY)
      window.location.href = '/login'
    }
    Message({
      message: error.response.data.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

/**
 * 封装 get 请求
 * @param {*} url
 * @param {*} params 查询参数
 * @returns
 */
export function fetch (url, params = {}) {
  // const token = Cookies.get(TOKEN_KEY) === undefined ? "11111" : Cookies.get(TOKEN_KEY);
  const token = Storage.getItemForLs(TOKEN_KEY)
  return axios({
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: token
      // Token: token
    },
    url: process.env.VUE_APP_BASE_URL + url,
    method: 'get',
    params
  })
}

export function fetchFormData (url, type = 'post', data = {}) {
  // const token = Cookies.get(TOKEN_KEY) === undefined ? "11111" : Cookies.get(TOKEN_KEY);
  const token = Storage.getItemForLs(TOKEN_KEY)
  return axios({
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token
      // Token: token
    },
    url: process.env.VUE_APP_BASE_URL + url,
    method: type,
    data: qs.stringify(data)
  })
}

/**
 * 封装非 get 请求
 * @param {*} url
 * @param {*} type 请求类型
 * @param {*} data js 对象
 * @returns
 */
export function fetch_ (url, type = 'post', data = {}) {
  // const token = Cookies.get(TOKEN_KEY) === undefined ? "11111" : Cookies.get(TOKEN_KEY);
  const token = Storage.getItemForLs(TOKEN_KEY)
  return axios({
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: token
      // Token: token
    },
    url: process.env.VUE_APP_BASE_URL + url,
    method: type,
    data: JSON.stringify(data)
  })
}

export function fetchImage (url, type = 'post', data) {
  // const token = Cookies.get(TOKEN_KEY) === undefined ? "11111" : Cookies.get(TOKEN_KEY);
  const token = Storage.getItemForLs(TOKEN_KEY)
  return axios({
    headers: {
      'Content-Type': 'multipart/formData',
      Authorization: token,
      Token: token
    },
    url: process.env.VUE_APP_BASE_URL + url,
    method: type,
    data
  })
}

// 获取 Cookies
export function getCookies (key) {
  return Cookie.get(key) || ''
}

// 设置 Cookies
export function setCookies (key, value) {
  Cookie.set(key, value)
}

// eslint-disable-next-line no-unused-vars
