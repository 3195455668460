<template>
  <div>
    <span style="float: left;margin-left: 10px" :class="[isActive?'go':'aa']"
          @click="turnAround">
        <svg-icon
            icon-class="collapse"
            class="icon"
            style="width: 2em;height: 1.5em"
        />
    </span>
    <el-menu
        style="float: right"
        class="el-menu-demo"
        mode="horizontal"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b">
      <el-submenu index="1" style="width: 200px;height: 54px">
        <template v-slot:title>
          <div class="demo-type">
            <el-avatar :size="37" :src="imgSrc" @error="errorHandler">
              <img :src="imgSrc"/>
            </el-avatar>&nbsp;&nbsp;
            <span style="width: 20px">{{ displayName }}</span>
          </div>
        </template>
        <el-menu-item index="2-1">个人中心</el-menu-item>
        <el-menu-item index="2-2" @click="handleLoginOut">退出登录</el-menu-item>
      </el-submenu>
    </el-menu>
  </div>

</template>

<script>

import Storage from '@/utils/storage'

export default {
  name: 'headerVue',
  props: {
    displayName: {
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      imgSrc: Storage.getItemForLs('user').userImg
    }
  },
  methods: {
    handleSelect (index, indexPath) {
      // console.log(index, indexPath)
    },
    turnAround () {
      this.$emit('changeActive')
    },
    errorHandler () {
      return true
    },
    handleLoginOut () {
      const _this = this
      this.$Request
        .fetch_('/api/user/logOut', 'post', {})
        .then(function (result) {
          if (result.status === 200) {
            _this.$message.success(result.data)
          } else {
            _this.$message.error(result.data)
          }
        })
        .catch(function () {
          _this.isLoading = false
          _this.$message('服务器错误')
        })
      localStorage.clear()
      window.sessionStorage.clear()
      this.$router.push('/login')
    }
  }
}

</script>

<style scoped lang="scss">
.aa{
  transition: all 2s;
}
.go{
  transform:rotateY(-180deg);
  transition: all 2s;
  transform-origin:center;
}
.icon{
  color: white;
}
.el-menu-item{
  width: 130px;
}

::v-deep .el-submenu__title {
  .el-icon-arrow-down {
    display: none;
  }
}
</style>
