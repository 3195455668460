import Vue from 'vue'
import App from './App.vue'

// Echart 组件
import * as echarts from 'echarts'
// 引入 ElementUI 和主题样式
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// 引入 Jquery
// import $ from 'jquery'

import Vuex from 'vuex'

// Bus，用于组件间传递消息
import Bus from '@/utils/bus.js'
// Cookie 组件
import * as Cookie from '@/utils/cookie.js'
// Storage 组件
import Storage from '@/utils/storage.js'
// Axios Request 组件
import * as Request from '@/utils/request'
// 注册过滤器组件
import * as Filters from '@/utils/filter.js'
// Vuex Store 组件
import VuexStore from '@/utils/vuexStore.js'

// Common 组件
import * as Common from '@/utils/common.js'
// 打印组件
// import Print from 'vue-print-nb'
// import print from './print/packages/print'
// 引入全局样式
import './assets/scss/globalCss.scss'

// 引用icon
import '@/icons'

import Router from 'vue-router'
import router from './router'
/**
 * 引入 css
 */
// 引入图标库样式
import 'font-awesome/css/font-awesome.min.css'
// 在下面这样配置之后，在其他地方就可以直接通过this.$+组件名称的方式直接引用
Vue.prototype.$Bus = Bus
Vue.prototype.$Cookie = Cookie
Vue.prototype.$Storage = Storage
Vue.prototype.$Request = Request
Vue.use(Router)
Vue.prototype.$echarts = echarts
Vue.use(ElementUI)
Vue.prototype.$Common = Common
Object.keys(Filters).forEach((key) => {
  Vue.filter(key, Filters[key])
})
Vue.config.productionTip = false
Vue.prototype.$VuexStore = VuexStore
Vue.use(Vuex)
// Vue.use(print)
// Vue.use(Print)

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem(Request.TOKEN_KEY)
  if (token || to.path === '/login' || to.path === '/register') {
    next()
  } else {
    next({ path: '/login' })
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
