/**
 * 公共函数
 */

/**
 * 驼峰转下划线
 * @param {*} name
 * @returns
 */
export function camecaseToLine (name) {
  return name.replace(/([A-Z])/g, '_$1').toLowerCase()
}

/**
 * 调用：new Date().Format('yyyy-MM-dd hh:mm:ss');
 * fmt ---- 时间格式
 * hasweek ---- 用来表示是否显示星期
 */
// eslint-disable-next-line no-extend-native
Date.prototype.Format = function (fmt, hasweek) {
  const weekday = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
  const o = {
    'M+': this.getMonth() + 1,
    'd+': this.getDate(),
    'h+': this.getHours(),
    'm+': this.getMinutes(),
    's+': this.getSeconds(),
    'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
    S: this.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (const k in o) { if (new RegExp('(' + k + ')').test(fmt)) { fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)) } }

  return fmt + (hasweek ? '&nbsp;&nbsp;&nbsp;&nbsp;' + weekday[this.getDay()] : '')
}
