
// 这个是一级路由不导入Layout
// 构建路由对象
import Layout from '@/views/fragments/Layout'

const inputRouter = {
  // http://127.0.0.1:8080/#/
  path: '/input/',
  name: '入库',
  component: Layout,
  // 重定向
  // redirect: '/index',
  // 二级路由，渲染到该组件中的 <router-view />
  children: [
    {
      path: '/input/manage',
      name: '入库订单管理',
      // component: Home
      // ES6 箭头函数写法
      component: () =>
        import('@/views/input/inputManage')
      // meta: { requireAuth: true }
    }
  ]
}

export default inputRouter
