// 这个是一级路由不导入Layout
// 构建路由对象
import Layout from '@/views/fragments/Layout'

const stockRouter = {
  // http://127.0.0.1:8080/#/
  path: '/stock',
  name: '',
  component: Layout,
  // 重定向
  // redirect: '/index',
  // 二级路由，渲染到该组件中的 <router-view />
  children: [
    {
      path: '/',
      name: '库存管理',
      // component: Home
      // ES6 箭头函数写法
      component: () =>
        import('@/views/stock/stockManage')
      // meta: { requireAuth: true }
    },
    {
      path: 'history',
      name: '库存变化',
      // component: Home
      // ES6 箭头函数写法
      component: () =>
        import('@/views/stock/history/stockHistory.vue')
      // meta: { requireAuth: true }
    }
  ]
}

export default stockRouter
