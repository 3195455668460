
// 这个是一级路由不导入Layout
// 构建路由对象
const orderDetailRouter =
    {
      path: '/orderDetail',
      name: '出库详情',
      component: () => import('@/views/out/components/outDetail.vue')
    }

export default orderDetailRouter
