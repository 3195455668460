<template>
  <div style="width: 100%;height: 100%;display: flex;flex-direction: column;overflow-x: hidden">
    <div class="logo-div">
      <el-carousel
          height="60px"
          :autoplay="true"
          indicator-position="none"
      >
        <el-carousel-item v-for="item in 4" :key="item">
          <h3 class="small">{{ item }}</h3>
        </el-carousel-item>
      </el-carousel>
    </div>
<!--    <div class="menu-div">-->
      <el-menu
          :default-active="this.$route.path"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          :collapse="isActive"
          background-color="#545c64"
          text-color="#fff"
          router
      >
        <template v-for="item in navigationData">
          <template v-if="item.subItems && item.subItems.length > 0">
            <el-submenu :index="item.path" :key="item.index">
              <template v-slot:title>
                <svg-icon :class-name="item.icon" :icon-class="item.icon" class="icon-class" />
<!--                <i :class="item.icon"></i>-->
                <span>{{ item.title }}</span>
              </template>
              <el-menu-item
                  class="vue-sideMenu"
                  v-for="(subItem, i) in item.subItems"
                  :index="subItem.path"
                  :key="i"
              >
                {{ subItem.title }}
              </el-menu-item>
            </el-submenu>
          </template>
          <template v-else>
            <el-menu-item class="vue-sideMenu" :index="item.path" :key="item.index">
<!--              <i :class="item.icon"></i>-->
              <svg-icon :class-name="item.icon" :icon-class="item.icon" />
              <span>{{ item.title }}</span>
            </el-menu-item>
          </template>
        </template>

      </el-menu>
<!--    </div>-->

  </div>
</template>

<script>
export default {
  name: 'sideBar',
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      navigationData: [
        {
          title: '系统首页',
          path: '/index',
          icon: 'index'
        },
        {
          title: '库存管理',
          path: '/stock',
          icon: 'stock',
          subItems: [
            {
              title: '库存详情',
              path: '/stock'
            },
            {
              title: '库存变化',
              path: '/stock/history'
            }
          ]
        },
        {
          title: '出库订单',
          path: '/out/managePage',
          icon: 'out'
        },
        {
          title: '入库订单',
          path: '/input/manage',
          icon: 'input'
        },
        {
          title: '客户管理',
          path: '/users',
          icon: 'user'
        },
        {
          title: '供应商管理',
          path: '/suppliers',
          icon: 'user'
        },
        {
          title: '出库账单管理',
          path: '/outBill',
          icon: 'user'
        }
        // {
        //   title: '学生模块',
        //   path: '/student',
        //   icon: 'el-icon-user-solid',
        //   subItems: [
        //     {
        //       title: '个人中心',
        //       path: '/student/center'
        //     },
        //     {
        //       title: '待考考试',
        //       path: '/student/unTakenExam'
        //     },
        //     {
        //       title: '已考考试',
        //       path: '/student/takenExam'
        //     },
        //     {
        //       title: '历史成绩',
        //       path: '/student/historyScore'
        //     }
        //   ]
        // }
      ]
    }
  },
  methods: {
    handleOpen (key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath)
    }
  }

}
</script>

<style scoped lang="scss">
.el-menu{
  height: 100%;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 260px;
}
.menu-div {
  width: 100%;
}
.logo-div {
  height: 50px;
  width: 100%;
}

//幻灯片
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

/** 只设置左边菜单选中样式，不影响头部菜单 */
.vue-sideMenu.is-active {
}
/** 覆盖掉 app 样式，设置靠左对齐 */
.el-menu-item,
.el-submenu {
  text-align: left;
}
.svg-icon {
  margin: 5px 7px 0 3px;
}
</style>
