import Layout from '@/views/fragments/Layout'

// 构建路由对象
const FirstPageRouter = {
  // http://127.0.0.1:8080/#/
  path: '/',
  name: '',
  component: Layout,
  // 重定向
  redirect: '/index',
  // 二级路由，渲染到该组件中的 <router-view />
  children: [
    {
      path: '/index',
      name: '系统首页',
      // component: Home
      // ES6 箭头函数写法
      component: () =>
        import('@/views/components/index')
      // meta: { requireAuth: true }
    }
  ]
}

export default FirstPageRouter
