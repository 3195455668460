
// 这个是一级路由不导入Layout
// 构建路由对象
const inputDetailRouter =
    {
      path: '/inputDetail',
      name: '入库详情',
      component: () => import('@/views/input/components/inputDetail.vue')
    }

export default inputDetailRouter
