import Vue from 'vue'
import Router from 'vue-router'
// import Layout from '@/views/fragments/Layout'
import FirstPageControl from '@/router/modules/FirstPageControl'
import UserControl from '@/router/modules/userControl'
import InputControl from '@/router/modules/inputControl'
import OutControl from '@/router/modules/outControl'
import StockControl from '@/router/modules/stockControl'
import OrderDetail from '@/router/modules/orderDetail'
import inputDetailRouter from '@/router/modules/inputDetail'
import supplyRouter from '@/router/modules/supplyControl'
import OutBillManageRouter from '@/router/modules/OutBillManage'

Vue.use(Router)

const routes = [
  FirstPageControl,
  UserControl,
  InputControl,
  OutControl,
  StockControl,
  OrderDetail,
  inputDetailRouter,
  supplyRouter,
  OutBillManageRouter,
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/components/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/components/register.vue')
  },
  {
    path: '/print',
    name: 'print',
    component: () => import('@/views/print/printTemplate.vue')
  },
  {
    path: '/imgShow',
    name: 'imgShow',
    component: () => import('@/views/stock/components/imageShow.vue')
  }
]

export default new Router({
  // history:去除#号
  mode: 'history',
  routes
  // routes: [
  // //    一级路由到App.vue<router-view>
  //   {
  //     path: '/login',
  //     name: '',
  //     component: () => import('@/views/components/login.vue')
  //   },
  //   //  二级路由放到Layout的routerView
  //   {
  //     // http://127.0.0.1:8080/#/
  //     path: '/',
  //     name: '',
  //     component: Layout,
  //     // 重定向
  //     redirect: '/index',
  //     // 二级路由，渲染到该组件中的 <router-view />
  //     children: [
  //       {
  //         path: '/index',
  //         name: '系统首页',
  //         // component: Home
  //         // ES6 箭头函数写法
  //         component: () =>
  //           import('@/views/components/index')
  //         // meta: { requireAuth: true }
  //       }
  //     ]
  //   }
  // ]

})
