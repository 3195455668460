import Layout from '@/views/fragments/Layout'

// 构建路由对象
const OutBillManageRouter = {
  // http://127.0.0.1:8080/#/
  path: '/outBill',
  name: '',
  component: Layout,
  // 二级路由，渲染到该组件中的 <router-view />
  children: [
    {
      path: '/',
      name: '出库账单管理',
      // component: Home
      // ES6 箭头函数写法
      component: () =>
        import('@/views/out/bills/outBillManage')
      // meta: { requireAuth: true }
    }
  ]
}

export default OutBillManageRouter
